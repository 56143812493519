const faq = {
  title: 'Frequently asked questions',
  // description: '',
  items: [
    {
      q: 'How long will it take to get a new ecommerce platform setup?',
      a: (
        <>
          Depending on the size of the store and whether or not branding and a website design pre-exists. 
          You could expect it to take 2 to 6 weeks. In some more complex
          scenarios development could last a few months.
        </>
      ),
    },
    {
      q: 'How much does it cost to add a new feature?',
      a: "The cost would vary depending on the complexity. Basic features typically range between $240-$1000 and complex features $1000 and up.",
    },
    {
      q: 'How much does hosting cost?',
      a: 'Hosting cost vary depending on the size and scale of the project. The cheapest hosting cost would be around $5/month but on average $20-$40/month. Note CrowSec Software does not profit from hosting. Our profits derive from the inital sale or subscription of a product or service.',
    },
    {
      q: 'Do you do branding and design?',
      a: 'Yes if you do not already have a design / brand in place we can create it working with you or a designer.',
    },
  ],
}

export default faq
