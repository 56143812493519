export default {
  title: 'happy customers',
  items: [
    {
      name: 'Brandon Truong',
      description: 'Owner, Tsuga Digital Inc',
      avatar:
        '',
      children: (
        <>
        "comming soon"
        </>
      ),
    },
    {
      name: 'Chloe Best',
      description: 'CEO, Vibe Dance Studio',
      avatar: '',
      children: (
        <>
        "comming soon"
        </>
      ),
    },
    {
      name: 'Ben Hubbard',
      description: 'Owner/Operator, Refine',
      avatar: '',
      children: (
        <>
        "comming soon" 
        </>
      ),
    },
  ],
}
