import * as React from 'react'
import type { NextPage } from 'next'
import Image from 'next/image'
import {
  Container,
  Box,
  Stack,
  HStack,
  ButtonGroup,
  Icon,
  Heading,
  Text,
  Wrap,
  Tag,
  VStack,
} from '@chakra-ui/react'
import { SEO } from '../components/seo/seo'

import { FallInPlace } from '../components/motion/fall-in-place'
import { Hero } from '../components/hero'
import { Link, Br } from '@saas-ui/react'
import { Em } from '../components/typography'

import {
  FiAirplay,
  FiArrowRight,
  FiCode,
  FiCrosshair,
  FiFlag,
  FiLayers,
  FiShield,
  FiSun,
  FiTarget,
  FiTerminal,
  FiToggleLeft,
  FiTrendingUp,
  FiZap,
} from 'react-icons/fi'
import { Features } from 'components/features'
import { BackgroundGradient } from 'components/gradients/background-gradient'
import { Faq } from 'components/faq'
import { Pricing } from 'components/pricing/pricing'

import { ButtonLink } from 'components/button-link/button-link'
import { Testimonial, Testimonials } from 'components/testimonials'

import faq from 'data/faq'
import testimonials from 'data/testimonials'
import pricing from 'data/pricing'

import {
  Highlights,
  HighlightsItem,
  HighlightsTestimonialItem,
} from 'components/highlights'

const Home: NextPage = () => {
  return (
    <Box>
      <SEO
        title="crowsec software solutions"
        description="Purpose driven software."
      />
      <Box>
        <HeroSection />

        <HighlightsSection />

        <FeaturesSection />

        <TestimonialsSection />

        <PricingSection />

        <FaqSection />
      </Box>
    </Box>
  )
}

const HeroSection: React.FC = () => {
  return (
    <Box position="relative" overflow="hidden">
      <BackgroundGradient height="100%" />
      <Container maxW="container.xl" pt={{ base: 40, lg: 60 }} pb="40">
        <Stack direction={{ base: 'column', lg: 'row' }} alignItems="center">
          <Hero
            fontWeight={"thin"}
            id="home"
            justifyContent="flex-start"
            px="0"
            title={
              <FallInPlace>
                performance based 
                <Br /> digital solutions.
              </FallInPlace>
            }
            description={
              <FallInPlace delay={0.2} fontWeight="thin">
                We specialize in creating <Em>websites</Em>, <Em>native apps</Em> for both desktop and mobile platforms. 
                Whether you need a responsive website, a powerful desktop application, or a sleek mobile app, 
                our team has the expertise to bring your digital ideas to life. <Br /><Br />
                <Em>Let’s build something amazing together!</Em> 
              </FallInPlace>
            }
          >
            <FallInPlace delay={0.3}>
              <HStack pt="1" pb="8"  spacing="8">
         
              </HStack>

              <ButtonGroup spacing={4} alignItems="center">
                <ButtonLink colorScheme="primary" size="lg" href="/inquiry">
                  Free Quote
                </ButtonLink>
                <ButtonLink
                  size="lg"
                  href="/products"
                  variant="outline"
                  rightIcon={
                    <Icon
                      as={FiArrowRight}
                      sx={{
                        transitionProperty: 'common',
                        transitionDuration: 'normal',
                        '.chakra-button:hover &': {
                          transform: 'translate(5px)',
                        },
                      }}
                    />
                  }
                >
                  View Products
                </ButtonLink>
              </ButtonGroup>
            </FallInPlace>
          </Hero>
          <Box
            height="600px"
            position="absolute"
            display={{ base: 'none', lg: 'block' }}
            left={{ lg: '60%', xl: '55%' }}
            width="80vw"
            maxHeight='800'
            maxW="1100px"
            margin="0 auto"
          >
            <FallInPlace delay={1}>
              <Box overflow="hidden" height="100%" position="relative">
                <Image
                  src="/static/screenshots/ecommdash1.png"
                  layout="fixed"
                  width={1200}
                  height={762}
                  alt="Screenshot of a dashboard"
                  quality="75"
                  loading='lazy'
                />
              </Box>
            </FallInPlace>
          </Box>
        </Stack>
      </Container>

      <Features
        id="benefits"
        columns={[1, 2, 4]}
        iconSize={4}
        innerWidth="container.xl"
        mt={[-48, 12]}
        features={[
          { 
            title: 'Problem Coverage',
            icon: FiSun,
            description:
              'Purpose built solutions designed to meet your exact needs when general solutions are lacking.',
            iconPosition: 'left',
            delay: 0.6,
          },
          {
            title: 'Performance',
            icon: FiCrosshair,
            description:
              'We use modern technologies for bleeding edge speeds and efficiency. Not only creating a better user experience but effectivley lowering your hosting cost.',
            iconPosition: 'left',
            delay: 0.8,
          },
          {
            title: 'Productivity',
            icon: FiTarget,
            description:
              '100% problem coverage + Performance = Higher productivity + Happiness. A tailored solution means more time benefiting from it and less headaches.',
            iconPosition: 'left',
            delay: 1,
          },
          {
            title: 'Security',
            icon: FiShield,
            description: 'Following best practices when it comes to handling user data is our priority. We also do penetration testing on all our systems.',
            iconPosition: 'left',
            delay: 1.1,
          },
        ]}
        reveal={FallInPlace}
      />
    </Box>
  )
}

const HighlightsSection = () => {
  return (
    <Highlights>
      <HighlightsItem colSpan={[1, null, 2]} title="Our mission">
        <VStack alignItems="flex-start" spacing="8">
          <Text  fontSize="xl">
          At CrowSec Software, we are driven by a vision of making the world a better place through technology. 
          We develop software solutions that automate and integrate tasks, simplify complex problems, and enable 
          new possibilities. Whether it is data analysis, machine learning, web development, or cybersecurity, 
          we have the tools and expertise to help our clients achieve their goals. Our mission is to deliver high-quality, 
          innovative, and impactful software that makes life easier and more productive for everyone.
          </Text>


        </VStack>
      </HighlightsItem>
      <HighlightsItem title="Solid foundations">
        <Text  fontSize="lg">
          From the ground up we have built a team of diverse creatives and engineers
          which forms the backbone of our design process.
        </Text>
      </HighlightsItem>
      <HighlightsTestimonialItem
        name="Billy Best"
        description="Founder"
        avatar="/static/images/avatar-opt.webp"
        gradient={['cyan.200', 'purple.500']}
      >
        “I started my software journey in 2018 with a vision of transforming the world with code. CrowSec is the culmination
        and realization of that initial inspiration.”
      </HighlightsTestimonialItem>
      <HighlightsItem
        colSpan={[1, null, 2]}
        title="Get a head start on your next idea"
      >
        <Text  fontSize="lg">
          We take a careful consideration of all your digital needs. So we can
          design and build the perfect solution for you. Less time speant intergrating
          and meshing a plethora of services and more time benefiting from your solution.
        </Text>
        <Wrap mt="8">
          {[
            'authentication',
            'payments',
            'api',
            'cms',
            'multi-tenancy',
            'layouts',
            'billing',
            'payments',
            'testing',
            'server side rendering',
            'client side rendering',
            'native solutions',
            'documentation',
            'integrations',
            'storybooks',
            'theming',
            'branding',
            'crm',
            'responsiveness',
            'system design',
            'data',
            'smart contracts',
            "nft's",
          ].map((value) => (
            <Tag
              as={'li'}
              key={value}
              variant="subtle"
              colorScheme="purple"
              rounded="full"
              px="3"
            >
              {value}
            </Tag>
          ))}
        </Wrap>
      </HighlightsItem>
    </Highlights>
  )
}

const FeaturesSection = () => {
  return (
    <Features
      id="features"
      title={
        <Heading
          lineHeight="short"
          fontSize={['2xl', null, '4xl']}
          textAlign="left"
          as="p"
        >
          Not your average
          <Br /> software company.
        </Heading>
      }
      description={
        <>
          We work closely with clients and leverage wide spread experience.
          <Br />
          To build you exactly the solution you need.
        </>
      }
      align="left"
      columns={[1, 2, 3]}
      iconSize={4}
      features={[
        {
          title: 'New Applications.',
          icon: FiLayers ,
          description:
            'Bring your ideas to life or move your business logic into a native application built in Rust or React Native.',
          variant: 'inline',
        },
        {
          title: 'New Websites.',
          icon: FiAirplay ,
          description:
            'We offer fast, bleeding edge websites leveraging Nextjs app router. Include features such as authentication, billing and checkouts, forms, galleries, cms, crm, seo, and more',
          variant: 'inline',
        },
        {
          title: 'Business Tools.',
          icon: FiCode,
          description:
          <>
          We offer software as a service to make industry level tooling more available and easily deployable.  
          <Link href="/products/performance_inventory" className='text-purple-600'> Our first product</Link> will be launching end of the year
          </>,
          variant: 'inline',
        },
        {
          title: 'Performance.',
          icon: FiZap ,
          description:
            'We take performance seriously and use the latest techniques and technologies to ensure the fastest possible solution',
          variant: 'inline',
        },
        {
          title: 'Availability.',
          icon: FiFlag,
          description:
            "We offer a wide range of target deployments. Whether you need a solution to run on desktop, mobile or web. We got you covered.",
          variant: 'inline',
        },
        {
          title: 'Data.',
          icon: FiTrendingUp,
          description:
            'Leverage your solution to create new data points and give you more incite into your operations.',
          variant: 'inline',
        },
        {
          title: 'Themes.',
          icon: FiToggleLeft,
          description:
            'custom themes and layouts with content management (cms), darkmode support, and more.',
          variant: 'inline',
        },
        {
          title: 'Security.',
          icon: FiShield,
          description:
            'We follow best practices when handling our users data and run penetration and load test to ensure your system is sound. We have monitoring services coming soon!',
          variant: 'inline',
        },
        {
          title: 'Flexible.',
          icon: FiTerminal,
          description: (
            <>
              Solutions with less overhead then a web of services. You get portability, speed, scaling that general solutions will cost you an arm and a leg not to mention a headache to compete with.
            </>
          ),
          variant: 'inline',
        },
      ]}
    />
  )
}

const TestimonialsSection = () => {
  const columns = React.useMemo(() => {
    return testimonials.items.reduce<Array<typeof testimonials.items>>(
      (columns, t, i) => {
        columns[i % 3].push(t)

        return columns
      },
      [[], [], []]
    )
  }, [])

  return (
    <Testimonials
      title={testimonials.title}
      columns={[1, 2, 3]}
      innerWidth="container.xl"
    >
      <>
        {columns.map((column, i) => (
          <Stack key={i} spacing="8">
            {column.map((t, i) => (
              <Testimonial key={i} {...t} />
            ))}
          </Stack>
        ))}
      </>
    </Testimonials>
  )
}

const PricingSection = () => {
  return (
    <Pricing {...pricing}>
      <Text p="8" textAlign="center" >
        VAT may be applicable depending on your location.
      </Text>
    </Pricing>
  )
}

const FaqSection = () => {
  return <Faq {...faq} />
}

export default Home

export async function getStaticProps() {
  return {
    props: {
      announcement: {
        title: 'Web Services Sale! 🥳',
        href: 'https://billsdev.space',
      },
    },
  }
}
