import { HStack, Text } from '@chakra-ui/react'

export default {
  title: 'Options for everyone',
  description:
    'We design and build new solutions, upgrade existing systems and more. Check out our services below.',
  plans: [
    {
      id: '/services/packages/new-business',
      title: 'Startup Packages',
      description: 'We analyze, desgin and build a new solution for you.',
      price: 'starting at $800',
      features: [
        {
          title: 'Websites',
        },
        {
          title: 'Applications',
        },
        {
          title: 'Purpose driven design',
        },
        {
          title: 'Fast, performant',
        },
      ],
      action: {
        href: '/services/packages/new-business',
      },
    },
    {
      id: '/services/packages/upgrades',
      title: 'Upgrade Packages',
      description: 'Add features, integrations in your existing apps or websites.',
      price: 'starting at $240',
      isRecommended: true,
      features: [
        {
          title: 'Payments',
        },
        {
          title: 'CMR',
        },
        {
          title: 'CMS',
        },
        {
          title: 'SEO',
        },
        {
          title: 'Authentication',
        },
        {
          title: 'ETC',
        },
      ],
      action: {
        href: '/services/packages/upgrades',
      },
    },
    {
      id: 'services/packages/technology-consulting',
      title: 'Consulting',
      description: 'Discuss and address your digital infrastructure and business needs.',
      price: (
        <HStack>
          <Text textDecoration="line-through" fontSize="sm" color="gray.400">
            $120,-
          </Text>
          <Text>$100/hr</Text>
        </HStack>
      ),
      features: [
        {
          title: 'Identify bottlenecks, obstacles.',
        },
        {
          title: 'How to overcome bottlenecks, obstacles.',
        },
        {
          title: 'dev strategy analysis.',
        },
      ],
      action: {
        href: '/services/packages/technology-consulting',
      },
    },
  ],
}
